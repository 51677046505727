import React, { useMemo, useState, useEffect } from "react"
import Header from "../header"
import "./index.less"
import { navigate, Link } from "gatsby"
import { sort } from "ramda"
import dayjs from "dayjs"
import { lang } from "../../utils"
import Drop from "../drop"

const loadText = require("src/utils").loadText
const bmcData = loadText("bmc")
const eventData = loadText("event")
const blogData = loadText("blog")
const formData = loadText("form")
import { getStatus, getHeader } from "../../templates/event-singular"
import {
  ConsultationPopButton,
  SupportPopButton,
  SaleFormWrap,
  ZgaFormWrap,
} from "../../components/form-biz"
import { display, timeDiff } from "../../utils"
import VisibilitySensor from "react-visibility-sensor"
export const AnimationWrap = ({
  children,
  minTopValue = 100,
  className = "fadeInUp",
}) => {
  const [visible, setV] = useState(false)
  return (
    <VisibilitySensor
      minTopValue={minTopValue}
      partialVisibility={true}
      onChange={isVisible => {
        if (!visible && isVisible) {
          setV(true)
        }
      }}
    >
      <div className={visible ? className : "transparent"}>{children}</div>
    </VisibilitySensor>
  )
}
export const FirstVisibleWrap = ({ children, minTopValue = 100 }) => {
  const [visible, setV] = useState(false)
  return (
    <VisibilitySensor
      minTopValue={minTopValue}
      partialVisibility={true}
      onChange={isVisible => {
        if (!visible && isVisible) {
          setV(true)
        }
      }}
    >
      {children({ visible })}
    </VisibilitySensor>
  )
}
export const picClipAnimation = (className: string, flag: boolean) =>
  `${className} ${flag ? "clipUp" : "clipInit"}`

// export const PicClipProvider = ({ children, minTopValue = 100 }) => {
//   const [visible, setV] = useState(false)
//   const cx = (className: string) =>
//     `${className} ${visible ? "clipUp" : "clipInit"}`
//   return (
//     <VisibilitySensor
//       minTopValue={minTopValue}
//       partialVisibility={true}
//       onChange={isVisible => {
//         if (!visible && isVisible) {
//           setV(true)
//         }
//       }}
//     >
//       {children({})}
//     </VisibilitySensor>
//   )
// }

export const SolutionTopBanner = ({
  pic,
  picM,
  bannerPic,
  bgPic,
  bgColor,
  title,
  height = 676,
  subtitle,
  description,
  buttonText,
  button,
  animatedIcon,
  backgroundAnimated = false,
  extra,
  source,
  className,
  extraButton,
  iconBg,
}: any) => {
  const Button = button ? button : ConsultationPopButton
  useEffect(() => {
    if (backgroundAnimated) {
      const icon: any = document.querySelector(".banner-animatedIcon")
      const pic: any = document.querySelector(".banner-pic-wrapper")
      const bg: any = document.querySelector(".banner-bg-imgWrapper")
      const listener = (t: any) => (e: any) => {
        const windowWidth = window.innerWidth / 5
        const windowHeight = window.innerHeight / 5
        const mouseX = e.clientX / windowWidth
        const mouseY = e.clientY / windowHeight
        t.style.transform = `translate3d(${mouseX * 1.5}%, ${mouseY * 1.5}%, 0)`
        bg.style.transform = `translate3d(-${mouseX * 0.1}%, -${
          mouseY * 0.1
        }%, 0)`
      }
      const listener1 = listener(icon)
      const listener2 = listener(pic)

      if (icon) {
        icon.addEventListener("mousemove", listener1)
        return () => icon.removeEventListener("mousemove", listener1)
      }
      if (pic) {
        pic.addEventListener("mousemove", listener2)
        return () => pic.removeEventListener("mousemove", listener2)
      }
    }
  }, [])
  return (
    <div
      className={className}
      style={{ position: "relative", textAlign: "left", zIndex: 999 }}
    >
      <div className="headerContainer only-desktop headerContainer-desk">
        <Header logo="/logo-blue.png" />
      </div>

      <div
        className="page-middle only-desktop"
        style={{
          width: "720px",
          position: "absolute",
          top: 0,
          // overflow: "hidden",
          zIndex: 1000,
          height: "100%",
          left: "50%",
          transform: "translateX(-50% + 720px)",
        }}
      >
        {iconBg && (
          <div className="banner-animatedIcon-bg">
            <img src={iconBg} alt="kv-icon-bg" />
          </div>
        )}
        {animatedIcon && (
          <div className="banner-animatedIcon">
            <img src={animatedIcon} alt="kv-icon" />
          </div>
        )}
        {extra && extra}
        {pic && (
          <div className="banner-pic-wrapper" style={pic.style}>
            <div className={`${backgroundAnimated ? "movedownup" : ""}`}>
              <img
                alt={title}
                src={pic.src}
                style={{ width: "100%" }}
                className={`banner-pic ${backgroundAnimated ? "fadeUpIn" : ""}`}
              />
            </div>
          </div>
        )}
      </div>

      <div className="banner-bg" style={{ background: bgColor }}>
        <div
          className="banner-bg-imgWrapper"
          style={animatedIcon ? { width: "110%", height: "110%" } : {}}
        >
          <div
            className={`banner-bg-img ${backgroundAnimated ? "animated" : ""}`}
            style={{ backgroundImage: `url("${bgPic}")` }}
          ></div>
        </div>

        {bannerPic && (
          <div
            className="banner-bg-imgMobile"
            style={{
              backgroundImage: `url("${bannerPic.src}")`,
              ...bannerPic.style,
            }}
          ></div>
        )}
      </div>
      <div className="banner-pic-container page-middle">
        {picM && (
          <img
            src={picM.src}
            alt={title}
            style={picM.style}
            className="banner-pic-m"
          />
        )}
      </div>
      <div className="banner-topContainer">
        <div className="headerContainer">
          <span className="only-mobile">
            <Header logo="/logo-blue.png" />
          </span>
        </div>
        <div
          className="banner-topIntro page-middle"
          style={{ height: `${height - 66}px` }}
        >
          <h1
            className="banner-topIntro-title semi-bold"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="font-20 mt-24 semi-bold subtitle">{subtitle}</div>
          {description && (
            <div
              className="banner-topIntro-desc font-20 mt-36"
              style={{ maxWidth: "544px" }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          <span className="only-mobile">{extra && extra}</span>

          <div className="mt-56 btnWrap">
            {buttonText && (
              <Button buttonClassName="start-btn" source={source}>
                {buttonText}
              </Button>
            )}
            {extraButton && <div className="extra-button">{extraButton}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProductAction = ({
  title,
  content,
  buttonText,
  bgColor,
  bgPic,
  source,
}) => (
  <div className="product-action" style={{ background: bgColor }}>
    <div
      className="product-actionContent full-bg"
      style={{ backgroundImage: `url("${bgPic}")` }}
    >
      <div className="font-36 semi-bold t-color page-middle text-center">
        {title}
      </div>
      <div className="product-actionItem-container">
        {content.map(d => (
          <div className="product-actionItem">
            <img src={d.src} alt={d.description} />
            <div className="font-16 t-color">{d.description}</div>
          </div>
        ))}
      </div>
      <div className="product-action-button">
        <ConsultationPopButton buttonWidth={476} source={source}>
          {buttonText}
        </ConsultationPopButton>
      </div>
    </div>
  </div>
)

export const ProductSupport = ({
  bgColor,
  bgPic,
  woman,
  style = {},
  source,
}) => (
  <div className="product-support-container" style={style}>
    <div
      className="product-support block-middle"
      style={{ background: bgColor }}
    >
      <div
        style={{ backgroundImage: `url("${bgPic}")` }}
        className="product-support-bg"
      >
        <AnimationWrap>
          <div className="product-support-text">
            <div className="bmc-t1">{bmcData.support.title}</div>
            <div className="font-20 mt-8 subtitle">
              {bmcData.support.description}
            </div>
          </div>
        </AnimationWrap>
        {/* <img src={bgPic} alt="bg" className="product-support-bg" /> */}
        <img src={woman} alt="woman" className="product-support-woman" />
      </div>
    </div>
    <div className="text-center pt-64 btn-wrapper">
      <ConsultationPopButton buttonWidth={476} source={source}>
        {lang === "zh" ? formData.buttonText.consultation : "Get started"}
      </ConsultationPopButton>
    </div>
  </div>
)

export const ProductBlog = ({ blogs, title }) => {
  const _blogs = sort(
    (a, b) => {
      if (dayjs(a.date).isAfter(dayjs(b.date))) {
        return -1
      } else {
        return 1
      }
    },
    blogs.map(b => ({ ...b, date: b.wp_date || b.created_at }))
  ).slice(0, 3)
  return _blogs.length > 0 ? (
    <div className="productBlog">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color mobile-padding">{title}</div>
        {_blogs.map(
          ({
            author,
            title,
            excerpt,
            date,
            wp_date,
            slug,
            thumbnail,
            categories,
          }) => {
            const isPress =
              categories.map(p => p.name).indexOf("Press Release") > -1
            console.log(categories)
            return (
              <BlogCard
                author={author}
                thumbnail={
                  thumbnail && thumbnail.localFile
                    ? thumbnail.localFile.publicURL
                    : ""
                }
                type={isPress ? "press" : "blog"}
                title={title}
                excerpt={excerpt}
                date={wp_date || date}
                id={slug}
                openNew={true}
              />
            )
          }
        )}
      </AnimationWrap>
    </div>
  ) : null
}
export const BlogCard = ({
  id,
  title,
  author,
  type,
  date,
  excerpt,
  thumbnail,
  openNew = false,
}) => {
  return (
    <div
      className="blog-card"
      onClick={() => {
        const url = `/${lang === "zh" ? "" : `${type}/`}${id}`
        if (openNew) {
          window.open(url)
        } else {
          navigate(url)
        }
      }}
    >
      <div style={{ position: "relative" }}>
        <img src={thumbnail} alt={title} className="blog-thumbnail" />
        <Drop
          direction="up"
          color="#f5f9ff"
          style={{ bottom: "-1px", left: "60px" }}
        />
      </div>
      <div className="blog-card-content">
        <div
          className="font-20 semi-bold t-color"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div
          className="t-color mt-16 sub"
          onClick={e => {
            e.stopPropagation()
            const url = `/${
              lang === "en" || type === "press" ? `${type}/` : ""
            }author/${author.slug}/1`
            if (openNew) {
              window.open(url)
            } else {
              navigate(url)
            }
          }}
        >
          {blogData.subject.by}{" "}
          <span className="blog-card-author">{author.name}</span> |{" "}
          {display(date)}
        </div>
        <div
          className="mt-24 content only-desktop"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></div>
        <div className="only-mobile">
          <div
            className="mt-24 content "
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </div>
      </div>
      <div className="blog-card-more font-16 semi-bold">
        {eventData.subject.readMore}
      </div>
    </div>
  )
}

export const EventCard = ({
  id,
  name,
  startTime,
  endTime,
  excerpt,
  address,
  type,
  webinar_link,
  thumbnail,
  link,
}) => {
  const bgMap = {
    upcoming: "rgb(32,175,255)",
    ongoing: "rgb(0,206,190)",
    finished: "linear-gradient(90deg, #ffcc00 0%, #ffa900 100%)",
  }
  // console.log(webinar)
  const isSelfHosted = type === "Self_hosted"

  const status = useMemo(() => {
    return getStatus(startTime, endTime, webinar_link)
  }, [startTime, endTime, webinar_link])
  const buttonStatus = useMemo(() => {
    if (webinar_link) {
      return "webinar"
    }
    if (status !== "finished") {
      return isSelfHosted ? "register" : "book"
    }
    return "more"
  }, [webinar_link, status, isSelfHosted])
  const FormWrap = isSelfHosted ? ZgaFormWrap : SaleFormWrap
  return (
    <FormWrap source="event" defaultData={{ eventName: name }}>
      {({ setOpen }) => (
        <div className="blog-card">
          <div className="blog-card-imgContainer">
            <img
              onClick={e => {
                e.stopPropagation()
                if (link) {
                  window.open(link)
                }
              }}
              src={thumbnail.localFile.publicURL}
              alt={name}
              className="blog-thumbnail"
            />
            {!webinar_link && (
              <div style={{ background: bgMap[status] }}>
                {eventData.subject[status]}
              </div>
            )}
          </div>
          <div
            className="blog-card-content"
            onClick={() => {
              if (isSelfHosted) {
                window.open(link)
              } else {
                navigate(`/event/${id}`)
              }
            }}
          >
            <div className="font-20 semi-bold t-color">{name}</div>
            <div className="t-color mt-16 event-header">
              {getHeader({ status, startTime, endTime, address })}
            </div>
            <div
              className="mt-24 content"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            ></div>
          </div>

          <div
            className="blog-card-more font-16 semi-bold"
            onClick={() => {
              if (status !== "finished" && !webinar_link) {
                setOpen(true)
              } else {
                navigate(`/event/${id}`)
              }
            }}
          >
            {eventData.cardButtonText[buttonStatus]}
          </div>
        </div>
      )}
    </FormWrap>
  )
}

export const BlogCategory = ({ categories, active, type }) => (
  <div className="blog-category">
    <div className="title font-28 semi-bold">{blogData.subject.categories}</div>
    <div className="mt-32">
      {categories.map(({ name, slug }) => (
        <Link
          to={`/${
            lang === "zh" && type === "blog" ? "" : `${type}/`
          }category/${slug}/1`}
        >
          <div
            className={`item font-20 ${active === name ? "active" : ""}`}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        </Link>
      ))}
    </div>
  </div>
)

export const BlogCategoryM = ({ categories, active, type }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={`blog-categoryM ${open ? "open" : ""}`}>
      <div
        className="title font-28 semi-bold"
        onClick={() => {
          setOpen(!open)
        }}
      >
        {blogData.subject.categories}{" "}
        <img src="/arrow-down.svg" className="arrow-down" alt="arrow-down" />
      </div>
      <div className="dropdown">
        {categories.map(({ name, slug }) => (
          <Link to={`/${lang === "zh" ? "" : `${type}/`}category/${slug}/1`}>
            <div
              className={`item ${active === name ? "active" : ""}`}
              dangerouslySetInnerHTML={{ __html: name }}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

const easeOutQuad = t => t * (2 - t)
const frameDuration = 1000 / 60

export const CountUpAnimation = ({ children, duration = 2000 }) => {
  const countTo = parseInt(children, 10)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let frame = 0
    const totalFrames = Math.round(duration / frameDuration)
    const counter = setInterval(() => {
      frame++
      const progress = easeOutQuad(frame / totalFrames)
      setCount(countTo * progress)

      if (frame === totalFrames) {
        clearInterval(counter)
      }
    }, frameDuration)
  }, [])

  return <span>{Math.floor(count)}</span>
}
